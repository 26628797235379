/* You can add global styles to this file, and also import other style files */
@import "~bootstrap/dist/css/bootstrap.css";
@import "~@ng-select/ng-select/themes/default.theme.css";

body {
    margin: 0;
    padding: 0;
    background-color: whitesmoke!important;
    overflow-y: scroll; /* Add the ability to scroll */
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none; 
}
/* Hide scrollbar for Chrome, Safari and Opera */
.body::-webkit-scrollbar {
    display: none;
}

button {
    color: #444444;
    background-color: #f3f3f3;
    padding: 5px 10px;
    border-radius: 2px;
    outline: none;
    font-weight: bold;
    font-size: 0.8em;
}
button:disabled {
    color: #f3f3f3;
    background-color: #444444;
    cursor: not-allowed;
}
input, textarea {
    width: 100%;
    font-size: 0.8em;
    margin: 8px 0;
    padding: 10px;
    background-color: white;
    border: 1px solid black;
    border-radius: 3px;
}
select{
    background-color: white;
    border: 1px solid black;
    border-radius: 3px;
}
html {
    scroll-behavior: smooth;
}
.btn-danger{
    background: #373435!important;
    border: 1px solid #373435!important;
}
.btn-danger:hover {
    color: #fff;
    background-color: #B61F21!important;
    border-color: #B61F21!important;
}
.navbar-light .navbar-nav .nav-link:focus, .navbar-light .navbar-nav .nav-link:hover {
    color: #000000!important;
}
label {
    display: inline-block;
    margin-left: 1%;
    font-size: 1.1em;
    font-weight: 500;
}
.roundedInputs{
    font-size: 1em;
    border: 1px solid rgb(0 0 0 / 30%);
    border-radius: 12px;
    box-shadow: 0px 2px 5px rgb(0 0 0 / 40%);
}
.centerText{
    text-align: center;
}
.default_white{
    background: white;
    text-align: center;
    padding: 40px;
    font-size: 18px;
    margin: 0px -50px;
    margin-top: 60px;
}
.default_offwhite{
    background: whitesmoke;
    text-align: center;
    padding: 40px;
    font-size: 18px;
    margin: 0px -50px;
    margin-top: 60px;
}
.statsDiv_white{
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(240px, 1fr));
    gap: 20px;
    background: white;
}
.statsDiv_white_mobile{
    background: white;
}
.statsDiv_offwhite{
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(240px, 1fr));
    gap: 20px;
    background: whitesmoke;
}
.statsDiv_offwhite_mobile{
    background: whitesmoke;
}
.fullBackground{
    margin: 60px -50px 0px -50px;
}
.gridDivs{
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(240px, 1fr));
}
html {
    scroll-behavior: smooth;
}
// .headerImages{
//     border-radius: 100%;
//     margin: 20px 0px;
// }
.btn{
    border-radius: 10px!important;
}
.mr20bbtn{
    margin-right: 20px;
}
.snbtnrbgd{
    background-color: #bb2d3b!important;
    border-color: #bb2d3b!important;
}
